// Utils
import request from '../utils/request';

/**
 * Implement Authentication API
 */
export default class AuthAPI {
  constructor() {
    this.request = request;
  }

  /**
   * Change current user's password.
   */
  changePassword(data) {
    return this.request.post('v1/people/change-password', data);
  }

  /**
   * Get a token if user's credentials are valid.
   */
  login(data) {
    return this.request.post('login', data);
  }

  /**
   * Get current user data.
   */
  profile() {
    return this.request.get('v1/people/profile');
  }

  /**
   * Send recovery email.
   */
  sendRecoveryEmail(data) {
    return this.request.post('v1/people/recovery-email', data);
  }

  /**
   * Reset password.
   */
  resetPassword(data) {
    return this.request.post('v1/people/reset-password', data);
  }

  /**
   * Update current user's profile.
   */
  updateProfile(data) {
    return this.request.put('v1/people/profile', data);
  }

  /**
   * Generate a new valid token.
   */
  refreshToken(data) {
    return this.request.post('token/refresh', data);
  }

  /**
   * Verify email.
   */
  verifyEmail(data) {
    return this.request.post('v1/people/verify-email', data);
  }

  /**
   * Verify if token is still valid.
   */
  verifyToken(data) {
    return this.request.post('token/verify', data);
  }
}
