<template>
  <v-app id="inspire">
    <v-main>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-img src="@/assets/img/logo_color.png" class="mb-4" />

            <v-alert
              v-model="notification.show"
              :type="notification.type"
              transition="scale-transition"
              dismissible
            >
              {{ notification.message }}
            </v-alert>

            <Form @submit="login">
              <v-card class="elevation-12">
                <v-toolbar
                  color="primary"
                  dark
                  flat
                >
                  <v-toolbar-title>Recuperar contraseña</v-toolbar-title>
                </v-toolbar>

                <v-card-text>
                  <TextField
                    v-model="form.email"
                    label="Correo electrónico"
                    prepend-icon="mdi-account"
                    rules="required"
                  />
                </v-card-text>

                <v-card-actions class="d-flex flex-column justify-center">
                  <v-btn
                    class="px-10 mb-3"
                    color="primary"
                    type="submit"
                  >
                    <v-icon left>mdi-key</v-icon>
                    Recuperar contraseña
                  </v-btn>

                  <router-link :to="{ name: 'login' }">
                    Ir a inicio de sesión
                  </router-link>
                </v-card-actions>
              </v-card>
            </Form>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// API
import AuthAPI from '@/api/auth';

export default {
  name: 'PasswordRecoveryEmail',

  data: () => ({
    form: {
      email: null,
    },
    notification: {
      show: false,
      message: null,
      type: 'error',
    },
  }),

  created() {
    this.api = new AuthAPI();
  },

  methods: {
    async login() {
      this.$store.commit('showLoader');
      try {
        await this.api.sendRecoveryEmail(this.form);
        this.notification = {
          message: 'El link de recuperación ha sido enviado a tu correo',
          type: 'success',
          show: true,
        };
      } catch (error) {
        let message = 'Ocurrió un error. Por favor, vuelve a intentar.';

        if (error.status === 400) {
          message = 'No existe una cuenta con este correo electrónico';
        }

        this.notification = {
          message,
          type: 'error',
          show: true,
        };
      }
      this.$store.commit('hideLoader');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
